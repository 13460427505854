import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './footer.module.scss';

import EmailIcon from '-!svg-react-loader!../../assets/email.svg';
import { Link } from 'gatsby';
import PhoneIcon from '-!svg-react-loader!../../assets/phone.svg';
import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
  const phoneNumber = '+39 3200318685';
  const email = 'carroroxana@gmail.com';

  return (
    <footer>
      <div>
        <div className={styles.footerContentWrapper}>
          <div className={styles.services}>
            <h3>Servizi</h3>
            <ul>
              <li>
                <Link to="/dance/ballet">Danza Classica</Link>
              </li>
              <li>
                <Link to="/fitness/pilates">Pilates</Link>
              </li>
              <li>
                <Link to="/dance/hip-hop">Hip Hop</Link>
              </li>
            </ul>
          </div>
          <div className={styles.contacts}>
            <h3>Contatti</h3>
            <ul>
              <li
                className={styles.contactsItemWrapper}
                onClick={() => {
                  navigator.clipboard.writeText(phoneNumber);
                }}
                role="presentation"
              >
                <PhoneIcon className={styles.contactsIcon} />
                <span>&nbsp;&nbsp;{phoneNumber}</span>
              </li>
              <li
                className={styles.contactsItemWrapper}
                onClick={() => {
                  navigator.clipboard.writeText(email);
                }}
                role="presentation"
              >
                <EmailIcon className={styles.contactsIcon} />
                <span>&nbsp;&nbsp;{email}</span>
              </li>
            </ul>
          </div>
          <div className={styles.pilartDescription}>
            <h3>Pilart</h3>
            <div className={styles.certificationImages}>
              <StaticImage src="../../images/asi-logo.jpg" alt="AsiLogo" style={{ width: '5em', borderRadius: '10px' }} />
              <StaticImage src="../../images/coni-logo.jpg" alt="ConiLogo" style={{ width: '10em', borderRadius: '10px' }} />
            </div>
          </div>
        </div>
        <div className={styles.socialContainer}>
          <SocialIcon url="https://www.facebook.com/people/Pil-Art-ASD/61551065710690/" target="_blank" />
          <SocialIcon url="https://www.instagram.com/pil_art_danza/" target="_blank" />
        </div>
        <div className={styles.copyright}>Pilart Copyright © 2022</div>
      </div>
    </footer>
  );
};

export default Footer;
