// extracted by mini-css-extract-plugin
export var footerContentWrapper = "footer-module--footerContentWrapper--8AczH";
export var services = "footer-module--services--GVn7X";
export var contacts = "footer-module--contacts--VkbIm";
export var pilartDescription = "footer-module--pilartDescription--9ITIX";
export var copyright = "footer-module--copyright--sQRoO";
export var socialContainer = "footer-module--socialContainer--BaIQ1";
export var contactsItemWrapper = "footer-module--contactsItemWrapper--QaALQ";
export var contactsIcon = "footer-module--contactsIcon--NkhLN";
export var schoolDescription = "footer-module--schoolDescription--9hCiU";
export var certificationImages = "footer-module--certificationImages--N2UyZ";