import '../../styles/global.scss';

import * as styles from './layout.module.scss';

import Footer from '../Footer';
import Navbar from '../Navbar';
import React from 'react';

const Layout = ({ carousel, whyUsSection, hasMinHeight = false, children }) => {
  return (
    <div className="container">
      {carousel ? (
        <>
          {carousel}
          {whyUsSection}
          <div className={styles.homepageNavbarWrapper}>
            <Navbar isHomePage />
          </div>
        </>
      ) : (
        <Navbar />
      )}

      <div className={hasMinHeight ? styles.layoutWithMinHeight : styles.layout}>
        <main className={styles.content}>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
